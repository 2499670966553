<template>
  <div class="bg-success py-4">
    <div class="container">
      <BackButton variant-name="dark" extra-style="text-light" />
      <h1 class="text-secondary mb-4 mt-4">My Information</h1>
      <b-card
        bg-variant="primary"
        text-variant="dark"
        class="sign-in-card mb-4"
        title="Submit your information"
      >
        <b-card-text
          >To cater for your needs, please fill out the following form. We
          cannot guarantee that we will be able to cater for all dietary
          requirements, but we will do our best.
        </b-card-text>
        <b-card-text>
          Employment information is optional, but if you like to promote
          yourself to our sponsors, please fill out the form and accept the GDPR
          consent.
        </b-card-text>

        <b-card-footer
          >If you have any questions or concerns, please contact the organisers
          at <strong>info@ichack.org</strong>.</b-card-footer
        >
      </b-card>

      <b-card
        v-if="userPage"
        bg-variant="dark"
        text-variant="success"
        class="sign-in-card mb-4"
        title="Basic information"
      >
        <b-form-group id="input-group-1" label="Name" label-for="input-1">
          <b-form-input
            id="input-1"
            type="text"
            v-model="userPage.name"
            required
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Email:" label-for="input-2">
          <b-form-input
            id="input-2"
            type="text"
            disabled
            v-model="userPage.email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="T-shirt size:"
          label-for="input-2"
        >
          <b-form-select
            v-model="formData.shirtSize"
            :options="shirtSizeOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Dietary Requirements:"
          label-for="tags-component-select"
        >
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
            id="tags-component-select"
            v-model="formData.dietaryRequirements"
            class="mb-2 p-0"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <ul v-if="tags.length > 0" class="list-inline d-inline-block m-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="dark"
                    >{{ tag }}</b-form-tag
                  >
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableOptions"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Select your requirements</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Other dietary requirements:"
          label-for="other-dietary"
          v-if="otherDietary"
        >
          <b-form-input
            id="other-dietary"
            type="text"
            v-model="formData.otherDietary"
          ></b-form-input>
        </b-form-group>
        <b-button
          :disabled="busy"
          variant="success"
          class="text-dark"
          @click="saveData"
          >Save</b-button
        >
      </b-card>
      <b-card
        v-if="userPage"
        bg-variant="dark"
        text-variant="success"
        class="sign-in-card mb-4"
        title="Employment information"
      >
        <b-form-group id="input-group-2" label="CV:" label-for="input-file">
          <b-form-file
            id="input-file"
            v-model="currentFile"
            accept="application/pdf"
            :state="Boolean(currentFile)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        Max file size: 5MB
        <b-button
          @click="downloadCV"
          variant="primary"
          class="text-dark"
          :disabled="busy"
        >
          View Current</b-button
        ><b-button
          @click="currentFile = null"
          size="sm"
          v-if="currentFile"
          class="ml-4"
          >Remove File: {{ currentFile.name }}</b-button
        >
        <b-alert show class="mt-4">
          Only PDF files are allowed. If you do not have a PDF version of your
          CV, you can use a tool like
          <a href="https://smallpdf.com/word-to-pdf" class="text-danger">
            SmallPDF</a
          >
          to convert your CV to a PDF.
          <br />
        </b-alert>

        <b-form-group
          id="input-group-2"
          label="Employment Status:"
          label-for="input-2"
        >
          <b-form-select
            v-model="formData.employmentStatus"
            :options="employmentStatusOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Graduation year:"
          label-for="input-2"
        >
          <b-form-input
            id="grad-year"
            type="number"
            v-model="formData.graduationYear"
          ></b-form-input>
        </b-form-group>
        <p v-if="invalidGradYear" class="text-danger">
          Graduation year is not valid input
        </p>
        <b-form-group
          id="input-group-2"
          label="Year of study:"
          label-for="input-2"
        >
          <b-form-select
            v-model="formData.yearOfStudy"
            :options="yearOfStudyOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="GDPR Consent" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="formData.consent"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="true"
            >I consent to my data being stored and shared with sponsors and
            partners in accordance with our privacy policy</b-form-radio
          >
          <b-form-radio
            v-model="formData.consent"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="false"
            >I do not consent</b-form-radio
          >
        </b-form-group>
        <div>
          <b-button v-b-modal.modal-1>View privacy policy</b-button>
        </div>
        <b-modal
          id="modal-1"
          title="Privacy Policy"
          size="lg"
          ok-only
          ok-variant="danger"
        >
          <p>
            This privacy policy ("Policy") describes how IC Hack 23 and its
            affiliates ("we", "us", "our") collect, use, and share information
            about you when you attend a hackathon organized by us or when you
            use our websites, applications, and other online services
            (collectively, the "Services").
          </p>
          <p>
            By using the Services, you agree to the collection, use, and sharing
            of your information as described in this Policy. If you do not agree
            with our policies and practices, do not attend a hackathon or use
            the Services.
          </p>
          <h2>Collection of Information</h2>
          <p>
            We collect information about you in a number of ways when you attend
            a hackathon or use the Services.
          </p>
          <h3>1.1 Information You Provide to Us</h3>
          <p>
            We collect information that you provide directly to us. When you
            register for IC Hack, we may ask you for information such as your
            name, email address, phone number, CV, employment information,
            education status, and&nbsp;dietary requirements.
          </p>
          <h3>1.2 Information We Collect Automatically</h3>
          <p>
            We also collect information about you automatically when you use the
            Services. This includes information about your device and how you
            use the Services, such as your IP address, device type, browser
            type, operating system, and the pages you visit.
          </p>
          <h4>Use of Information</h4>
          <p>
            We use the information we collect about you to provide the Services
            and to improve your experience. This includes:
          </p>
          <ol>
            <li>
              Processing your registration for a hackathon and communicating
              with you about your registration and the hackathon
            </li>
            <li>
              Sharing your CV and employment information with sponsors and
              partners of the hackathon
            </li>
            <li>
              Sending you updates and news about future hackathons and other
              events organized by us
            </li>
            <li>
              Analyzing how you use the Services to improve and enhance the
              Services
            </li>
          </ol>
          <h4>Sharing of Information</h4>
          <p>We may share the information we collect about you as follows:</p>
          <ol>
            <li>
              With sponsors and partners of IC Hack to whom we may share your CV
              and employment information
            </li>
            <li>
              With third party service providers who provide services on our
              behalf, such as payment processors, data analysis firms, and
              hosting providers
            </li>
            <li>
              As required by law or to protect the rights, property, or safety
              of us or others
            </li>
            <li>
              The information we collect about you may be transferred to, and
              processed in, countries other than the country in which you are
              resident. These countries may have data protection laws that are
              different from the laws of your country.<br />
            </li>
          </ol>
          <h4>Data Retention</h4>
          <p>
            We will retain your information for as long as needed to provide the
            Services or as required by law.
          </p>
          <h4>Your Rights</h4>
          <p>
            You have the right to access, update, or delete the personal
            information we have collected about you. You may also have the right
            to object to the processing of your personal information, or to
            request that your personal information be transferred to another
            party. To exercise these rights, please contact us at
            privacy@ichack.org.
          </p>
          <h4>Children</h4>
          <p>
            The Services are not intended for persons under the age of 18. We do
            not knowingly collect personal information from persons under the
            age of 13. If you are a parent or guardian and you believe that your
            child under the age of 13 has provided personal information to us,
            please contact us using privacy@ichack.org.
          </p>
          <h4>Changes to This Policy</h4>
          <p>
            We may update our Policy from time to time. We will post any changes
            on this page and encourage you to review the Policy periodically.
            <br />
          </p>
        </b-modal>
        <b-button
          variant="success"
          class="text-dark mt-3"
          @click="saveData"
          :disabled="busy || invalidGradYear"
          >Save</b-button
        >
      </b-card>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      busy: false,
      projectName: "",
      currentFile: null,
      formData: {
        consent: true,
        shirtSize: null,
        yearOfStudy: null,
        graduationYear: "2020",
        employmentStatus: null,
        dietaryRequirements: [],
        otherDietary: "",
      },
      shirtSizeOptions: [
        { value: null, text: "Please select" },
        { value: "XS", text: "Extra Small" },
        { value: "S", text: "Small" },
        { value: "M", text: "Medium" },
        { value: "L", text: "Large" },
        { value: "XL", text: "Extra Large" },
        { value: "XXL", text: "Double Extra Large" },
      ],
      employmentStatusOptions: [
        { value: null, text: "Please select" },
        {
          value: "Intern/Placement",
          text: "I am looking for an internship/placement for this summer.",
        },
        {
          value: "Grad Role",
          text: "I am looking for a grad role from this summer.",
        },
        {
          value: "Intern/Placement next year",
          text: "I already have an internship/placement for this summer but I will be looking for an internship/placement next year.",
        },
        {
          value: "Grad Role next year",
          text: "I already have an internship/placement for this summer but I will be looking for a grad role next year.",
        },
        {
          value: "Not looking for role",
          text: "I am not looking for an internship/placement/grad role.",
        },
      ],
      dietaryRequirementsOptions: [
        "Vegetarian",
        "Vegan",
        "Gluten Free",
        "Halal",
        "Kosher",
        "Other",
      ],
      yearOfStudyOptions: [
        { value: null, text: "Please select" },
        { value: "1", text: "1st year" },
        { value: "2", text: "2nd year" },
        { value: "3", text: "3rd year" },
        { value: "4", text: "4th year" },
        { value: "5", text: "5th year" },
        { value: "MSc", text: "MSc" },
        { value: "PhD", text: "PhD" },
        { value: "Recent Grad", text: "Recent Grad" },
        { value: "Other", text: "Other" },
      ],
    };
  },
  computed: {
    ...mapState(["userName"]),
    ...mapGetters({
      participantInfo: "getCandidateData",
      userPage: "userStore/user",
      uid: "userStore/uid",
      idToken: "idToken",
    }),
    availableOptions() {
      if (!this.formData.dietaryRequirements) {
        return this.dietaryRequirementsOptions;
      }
      return this.dietaryRequirementsOptions.filter(
        (opt) => this.formData.dietaryRequirements.indexOf(opt) === -1
      );
    },
    numericGradYear() {
      // console.log(this.formData);
      return parseInt(this.formData.graduationYear);
    },
    invalidGradYear() {
      return this.numericGradYear < 2000 || this.numericGradYear > 2050;
    },
    otherDietary() {
      return this.formData.dietaryRequirements.indexOf("Other") != -1;
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    ...mapActions(["loadUserPage"]),
    async refreshData() {
      this.busy = true;
      if (!this.userPage) {
        await this.store.dispatch("userStore/openDBChannel");
      }
      console.log("refreshing data", this.uid, this.userPage.meta);
      if (this.userPage.meta) {
        this.formData.consent = this.userPage?.meta?.consent ? true : false;
        this.formData.shirtSize = this.userPage?.meta?.shirtSize;
        this.formData.yearOfStudy = this.userPage?.meta?.yearOfStudy;
        this.formData.graduationYear = this.userPage?.meta?.graduationYear;
        this.formData.employmentStatus = this.userPage?.meta?.employmentStatus;
        this.formData.dietaryRequirements =
          this.userPage?.meta?.dietaryRequirements;
      }
      this.busy = false;

      // Split "Other - ..." requiremet if exists
      this.formData.dietaryRequirements.forEach((e, i) => {
        if (e.startsWith("Other - ")) {
          this.formData.dietaryRequirements[i] = "Other";
          this.formData.otherDietary = e.slice(8);
        }
      });
      this.saEvent(`PROFILE_REFRESH`, { uid: this.uid });
    },
    downloadURI(uri, name) {
      console.log("download uri", uri, name);
      let link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadCV() {
      this.busy = true;
      console.log("download cv", this.uid);
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "getSingleUsersEmploymentStats",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              hid: this.uid,
            }),
          }
        );
        if (response.ok) {
          let res = await response.json();
          console.log("downloading ", res.cv);
          if (res.cv) {
            this.downloadURI(res.cv, "cv.pdf");
          } else {
            this.$bvToast.toast("CV has not been saved yet", {
              title: "Unable to download CV",
              variant: "danger",
              autoHideDelay: 5000,
            });
          }
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to download CV",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
        this.saEvent(`CV_DOWNLOAD_ERROR`, { uid: this.uid, error: error });
      } finally {
        this.busy = false;
      }
      this.saEvent(`CV_DOWNLOAD`, { uid: this.uid });
    },
    async saveData() {
      this.busy = true;
      if (this.currentFile) {
        this.uploadResume();
      }
      try {
        // If other was selected, add the elaboration onto the requirement
        let updatedDietary = [...this.formData.dietaryRequirements];
        if (this.otherDietary) {
          updatedDietary[
            updatedDietary.indexOf("Other")
          ] = `Other - ${this.formData.otherDietary}`;
        }

        let response = await fetch(
          process.env.VUE_APP_API_HOST + "updateProfile",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              ...this.formData,
              dietaryRequirements: updatedDietary,
              resume:
                this.currentFile || this.userPage?.meta?.resume ? true : false,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          console.log(res);
          this.$bvToast.toast("Profile updated", {
            title: "All changes saved",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.saEvent(`PROFILE_UPDATE_ERROR`, { uid: this.uid, error: text });
          this.$bvToast.toast(text, {
            title: "Unable to update",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
        this.saEvent(`PROFILE_UPDATE_ERROR`, { uid: this.uid, error: error });
      } finally {
        this.loadUserPage();
        this.busy = false;
      }
      this.saEvent(`PROFILE_UPDATE`, { uid: this.uid });
    },
    uploadResume() {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "users/" +
          this.uid +
          "/resume/" +
          this.userPage.name +
          "-" +
          this.uid +
          ".pdf"
      );
      const uploadTask = uploadBytesResumable(storageRef, this.currentFile, {
        contentType: "application/pdf",
      });
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
          });
        }
      );
      this.saEvent(`PROFILE_UPDATE_ERROR`, { uid: this.uid });
    },
  },
  components: {
    BackButton,
  },
};
</script>

<style lang="scss" scoped>
#tags-component-select.sr-only {
  display: none;
}
button.tags-component-select {
  color: #000;
}
</style>
