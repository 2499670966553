<template>
  <div class="d-flex justify-content-center align-items-center outer">
    <b-card
      bg-variant="secondary"
      text-variant="white"
      title="Scan your ticket"
      class="text-center sign-in-card"
    >
      <b-card-text>{{ userPage?.name }}</b-card-text>
      <img :src="userPage?.qrCodeUrl" />
      <b-card-text class="mt-4"
        ><h3 class="text-dark">{{ userPage?.barcode }}</h3></b-card-text
      >

      <b-button
        @click="$router.push('/main')"
        class="btn btn-dark mt-4 btn-block"
      >
        Done
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TicketPage",
  components: {},
  mounted() {
    console.log("signed in", this.isSignedIn);
    if (!this.isSignedIn) this.$router.push("/sign-in");
  },
  data: () => {
    return {
      text: "",
    };
  },
  computed: {
    ...mapGetters(["getName", "uid", "isSignedIn", "userPage"]),
    photo: function () {
      return this.$store?.state?.user?.photoURL;
    },
    name: function () {
      return this.$store?.state?.user?.displayName
        ? this.$store.state.user.displayName
        : "Loading name... This may take some time if you are a new user";
    },
  },
  methods: {
    ...mapActions(["loadUserPage"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  background: $primary;
  width: 100vw;
  height: 100vh;
}
.sign-in-card {
  max-width: 30rem;
}
.card-title {
  font-size: 2rem;
}
</style>
