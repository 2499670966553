<template>
  <div>
    <div class="container">
      <BackButton />
      <h3 class="text-success mt-4">Schedule</h3>
      <h4>
        <span
          :class="
            isFirst
              ? 'btn btn-lg btn-success   text-dark '
              : 'btn btn-lg btn-dark btn-outline  text-muted'
          "
          @click="
            () => {
              isFirst = true;
            }
          "
          >4th</span
        >
        <span class="text-muted mx-2">|</span>
        <span
          :class="
            !isFirst
              ? 'btn btn-lg btn-success   text-dark '
              : 'btn btn-lg btn-dark btn-outline  text-muted'
          "
          @click="
            () => {
              isFirst = false;
            }
          "
          >5th</span
        >
      </h4>
      <vertical-timeline :inputData="currentView" :reversed="reversed" />
    </div>
  </div>
</template>

<script>
import events from "../data/schedule.json";
import VerticalTimeline from "./TimelineVertical.vue";
import BackButton from "./BackButton.vue";
export default {
  name: "SectionTimeline",
  components: {
    VerticalTimeline,
    BackButton,
  },
  data: () => {
    return {
      events,
      reversed: false,
      isFirst: true,
    };
  },
  computed: {
    firstDay() {
      return this.filterDates(
        Date.parse(this.events.start),
        Date.parse(this.events.mid)
      );
    },
    secondDay() {
      return this.filterDates(
        Date.parse(this.events.mid),
        Date.parse(this.events.end)
      );
    },
    currentView() {
      return this.isFirst ? this.firstDay : this.secondDay;
    },
  },
  methods: {
    filterDates(startDate, endDate) {
      return this.events.schedule.filter((entry) => {
        let date = Date.parse(entry.date);
        return date >= startDate && date < endDate;
      });
    },
  },
};
</script>

<style></style>
