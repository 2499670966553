<template>
  <div id="app">
    <router-view v-if="$store.state.ready"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => {
    return {};
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
</style>
