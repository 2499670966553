<template>
  <div class="container">
    <div class="d-sm-none bg-danger m-4">
      <h1 data-cursor-hover><strong>IC Hack 23</strong></h1>
      <h3>
        <span class="highlight type-area"></span>
      </h3>
      <h3 data-cursor-hover>4-5 February 2023 @ Imperial College London</h3>
    </div>

    <div class="row mt-10 flex-column-reverse flex-xl-row">
      <div class="col-xl-6 infos">
        <div class="time">
          <h3>Hacking begins in</h3>
          <h3>{{ days }} Days</h3>
          <h3>{{ hours }} Hours</h3>
          <h3>{{ minutes }} Minutes</h3>
          <h3>{{ seconds }} Seconds</h3>
        </div>
        <div>
          <b-button
            data-cursor-hover
            pill
            lg="4"
            variant="outline-danger"
            class="hero-btn"
            ><h3 data-cursor-hover>Get Tickets</h3></b-button
          >
        </div>
      </div>

      <div class="col-xl-6 splodge md-order-first d-sm-block d-none">
        <img
          data-cursor-hover
          src="../assets/splodge.svg"
          alt="Burst of color"
          class="splodge-image"
        />
        <div class="splodge-contents">
          <h1 data-cursor-hover><strong>IC Hack 23</strong></h1>
          <h3>
            <span class="highlight type-area"></span>
          </h3>
          <h3 data-cursor-hover>4-5 February 2023 @ Imperial College London</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "SectionHero",
  data() {
    return {
      end: new Date("2023-02-04T10:00:00"),
    };
  },

  computed: {
    days() {
      return Math.floor((this.end - new Date()) / 1000 / 60 / 60 / 24);
    },
    hours() {
      return Math.floor((this.end - new Date()) / 1000 / 60 / 60) % 24;
    },
    minutes() {
      return Math.floor((this.end - new Date()) / 1000 / 60) % 60;
    },
    seconds() {
      return Math.floor((this.end - new Date()) / 1000) % 60;
    },
    display() {
      return `${this.hours}h ${this.minutes}m ${this.seconds}s`;
    },
  },
  created() {
    setInterval(this.update, 1000);
  },
  mounted() {
    const options = {
      strings: ["The uk's Largest student run hackathon", "Powered by Cisco"],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
      showCursor: false,
    };
    new Typed(".type-area", options);
  },
  methods: {
    update() {
      this.end = new Date("2023-02-04T10:00:00");
    },
  },
};
</script>

<style lang="scss">
.splodge-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -40%);
  text-align: left;
}
.splodge-image {
  max-width: 100%;
}
.highlight {
  background-color: $navy;
  color: $peach;
  padding: 0 0.5rem;
}
.splodge {
  color: $navy;
}
.time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 5rem;
  color: $purple;
}
.infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-width: medium;
}
</style>
