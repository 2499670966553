<template>
  <div class="container pt-4">
    <BackButton variant-name="success" extra-style="text-dark" />
    <h1 class="mt-4">
      <h2>Meet the Team</h2>
    </h1>
    <SectionTeamVue />
  </div>
</template>

<script>
import SectionTeamVue from "@/components/SectionTeam.vue";
import BackButton from "@/components/BackButton.vue";
export default {
  components: {
    SectionTeamVue,
    BackButton,
  },
};
</script>

<style lang="scss">
.curvy-purple-box {
  border-radius: 30px;
  padding: 0.2em 0.2em 0.3em 0.2em;
  background: $purple;
}
</style>
