<template>
  <div class="bg-primary my-page">
    <b-modal
      scrollable
      size="xl"
      id="my-modal"
      ok-variant="danger"
      ok-only
      ok-title="Close"
    >
      <div class="d-flex justify-center flex-column" v-if="participantInfo?.cv">
        <pdf
          :src="pdfdata"
          v-for="i in numPages"
          :key="i"
          :id="i"
          :page="i"
          :scale.sync="scale"
          :annotation="true"
          :resize="true"
        >
          <template slot="loading"> loading content here... </template>
        </pdf>
        <a
          :href="participantInfo?.cv"
          :download="`${participantInfo?.name} - CV`"
          >Download CV</a
        >
      </div>
    </b-modal>
    <div class="container pt-4">
      <b-card
        bg-variant="dark"
        text-variant="light"
        title="Access Participant info"
      >
        <ais-instant-search
          v-if="searchClient"
          :index-name="index"
          :search-client="searchClient"
        >
          <div class="flexbox mb-4">
            <div class="flexbox-col-4">
              <b-card-text>Employment Status</b-card-text>
              <ais-refinement-list attribute="meta.employmentStatus">
                <template v-slot:item="{ item, refine, createURL }">
                  <b-form-checkbox
                    disabled
                    v-model="item.isRefined"
                    @click.prevent="refine(item.value)"
                    ><a
                      :href="createURL(item.value)"
                      :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                      @click.prevent="refine(item.value)"
                    >
                      <ais-highlight attribute="item" :hit="item" />
                      ({{ item.count.toLocaleString() }})
                    </a></b-form-checkbox
                  >
                </template>
              </ais-refinement-list>
              <!-- <ais-refinement-list attribute="meta.employmentStatus" /> -->
            </div>
            <div class="flexbox-col-3">
              <b-card-text>Graduation Year</b-card-text>
              <ais-refinement-list attribute="meta.graduationYear">
                <template v-slot:item="{ item, refine, createURL }">
                  <b-form-checkbox
                    disabled
                    v-model="item.isRefined"
                    @click.prevent="refine(item.value)"
                    ><a
                      :href="createURL(item.value)"
                      :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                      @click.prevent="refine(item.value)"
                    >
                      <ais-highlight attribute="item" :hit="item" />
                      ({{ item.count.toLocaleString() }})
                    </a></b-form-checkbox
                  >
                </template>
              </ais-refinement-list>
            </div>
            <div class="flexbox-col-3">
              <b-card-text>Year of Study</b-card-text>
              <!-- <ais-refinement-list attribute="meta.yearOfStudy" /> -->
              <ais-refinement-list attribute="meta.yearOfStudy">
                <template v-slot:item="{ item, refine, createURL }">
                  <b-form-checkbox
                    disabled
                    v-model="item.isRefined"
                    @click.prevent="refine(item.value)"
                    ><a
                      :href="createURL(item.value)"
                      :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                      @click.prevent="refine(item.value)"
                    >
                      <ais-highlight attribute="item" :hit="item" />
                      ({{ item.count.toLocaleString() }})
                    </a></b-form-checkbox
                  >
                </template>
              </ais-refinement-list>
            </div>
          </div>
          <ais-clear-refinements
            :class-names="{
              'ais-ClearRefinements-button': '',
              'ais-ClearRefinements-button--disabled': 'd-none',
            }"
          >
            <template v-slot="{ canRefine, refine, createURL }">
              <a
                class="btn btn-danger mb-4"
                :href="createURL()"
                @click.prevent="refine"
                v-if="canRefine"
              >
                Clear all refinements
              </a>
            </template>
            <template v-slot:resetLabel>Clear refinements</template>
          </ais-clear-refinements>
          <div>
            <ais-search-box class="searchbox">
              <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search"
                  :value="currentRefinement"
                  @input="refine($event.currentTarget.value)"
                />
                <span :hidden="!isSearchStalled">Loading...</span>
              </template>
            </ais-search-box>
            <ais-hits>
              <template v-slot="{ items }">
                <b-table :items="items" :fields="fields" striped responsive>
                  <template #cell(name)="data">
                    <span v-if="data.item?.meta?.consent">{{
                      data.item.name
                    }}</span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                  <template #cell(cv)="data">
                    <span v-if="data.item?.meta?.consent">
                      <a
                        href="#"
                        class="text-danger"
                        @click="showModal(data.item.objectID)"
                        >View CV
                      </a>
                    </span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                  <template #cell(employmentStatus)="data">
                    <span v-if="data.item?.meta?.consent">
                      {{ data.item.meta.employmentStatus }}
                    </span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                  <template #cell(email)="data">
                    <span v-if="data.item?.meta?.consent">{{
                      data.item.email
                    }}</span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                  <template #cell(yearOfStudy)="data">
                    <span v-if="data.item?.meta?.consent">
                      {{ data.item.meta.yearOfStudy }}
                    </span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                  <template #cell(graduationYear)="data">
                    <span v-if="data.item?.meta?.consent">
                      {{ data.item.meta.graduationYear }}
                    </span>
                    <span v-else>GDPR REDACTED</span>
                  </template>
                </b-table>
              </template>
            </ais-hits>
            <div class="flexbox">
              <ais-pagination class="flexbox-col-2 mr-4">
                <template
                  v-slot="{
                    currentRefinement,
                    nbPages,
                    pages,
                    isFirstPage,
                    isLastPage,
                    refine,
                    createURL,
                  }"
                >
                  <ul class="pagination">
                    <li class="page-item" v-if="!isFirstPage">
                      <a
                        class="page-link text-dark"
                        :href="createURL(0)"
                        @click.prevent="refine(0)"
                      >
                        ‹‹
                      </a>
                    </li>
                    <li class="page-item" v-if="!isFirstPage">
                      <a
                        class="page-link text-dark"
                        :href="createURL(currentRefinement - 1)"
                        @click.prevent="refine(currentRefinement - 1)"
                      >
                        ‹
                      </a>
                    </li>
                    <li class="page-item" v-for="page in pages" :key="page">
                      <a
                        class="page-link text-dark"
                        :href="createURL(page)"
                        :style="{
                          fontWeight: page === currentRefinement ? 'bold' : '',
                        }"
                        @click.prevent="refine(page)"
                      >
                        {{ page + 1 }}
                      </a>
                    </li>
                    <li class="page-item" v-if="!isLastPage">
                      <a
                        class="page-link text-dark"
                        :href="createURL(currentRefinement + 1)"
                        @click.prevent="refine(currentRefinement + 1)"
                      >
                        ›
                      </a>
                    </li>
                    <li class="page-item" v-if="!isLastPage">
                      <a
                        class="page-link text-dark"
                        :href="createURL(nbPages)"
                        @click.prevent="refine(nbPages)"
                      >
                        ››
                      </a>
                    </li>
                  </ul>
                </template>
              </ais-pagination>
              <ais-hits-per-page
                :items="[
                  { text: '1 hits per page', value: 1 },
                  { text: '10 hits per page', value: 10, default: true },
                  { text: '100 hits per page', value: 100 },
                ]"
                class="flexbox-col-4"
              >
                <template v-slot="{ items, refine }">
                  <b-form-select
                    v-model="perPage"
                    :options="items"
                    @input="refine(perPage)"
                  ></b-form-select>
                </template>
              </ais-hits-per-page>
            </div>
          </div>
        </ais-instant-search>
      </b-card>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import pdf from "pdfvuer";

// import "instantsearch.css/themes/satellite-min.css";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CvPage",
  mounted() {
    if (!this.$store.getters.isSignedIn) this.$router.push("/sign-in");

    this.searchClient = algoliasearch(this.appID, this.userPage.searchKey);
  },
  watch: {
    show: function (s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchCandidateData: "fetchCandidateData",
    }),
    async showModal(hid) {
      this.isBusy = true;
      try {
        await this.fetchCandidateData(hid);
        this.getPdf();

        this.$bvModal.show("my-modal");
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    getPdf() {
      this.pdfdata = pdf.createLoadingTask(this.participantInfo?.cv);
      this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
  },
  computed: {
    ...mapGetters({
      participantInfo: "getCandidateData",
      userPage: "userStore/user",
    }),
  },
  components: {
    pdf,
  },
  data: () => {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      searchClient: null,
      index: "prod_ichack23",
      appID: "1ETLLEK81R",
      totalRows: 1000,
      currentPage: 1,
      perPage: 10,
      pageOptions: [1, 10, 15, { value: 1000, text: "Show a all" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        "name",
        { key: "cv", label: "CV" },
        "email",
        "employmentStatus",
        "graduationYear",
        "yearOfStudy",
      ],

      busy: false,
      loading: true,
      email: "",
    };
  },
};
</script>

<style lang="scss">
.ais-RefinementList-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.my-page {
  min-height: 100vh;
}

div.page {
  margin: auto;
}

.results-header {
  border-radius: 15px;
  border-top: 0.5em solid;
}

.searchbox {
  margin-bottom: 1em;
}

.flexbox {
  display: flex;
}

.flexbox-col-4 {
  flex: 4;
}

.flexbox-col-3 {
  flex: 3;
}

.flexbox-col-2 {
  flex: 2;
}

@media screen and (max-width: 1000px) {
  .flexbox {
    flex-direction: column;
  }
}
</style>
