<template>
  <div>
    <h5>{{ name }}</h5>
    {{ description }}
    <b-list-group class="mt-2">
      <b-list-group-item
        variant="dark"
        v-for="example in examples"
        :key="example"
      >
        {{ example }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    examples: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
