<template>
  <div class="bg-success py-4">
    <div class="container">
      <BackButton variant-name="dark" extra-style="text-light" />
      <h1 class="text-secondary mb-4 mt-4">Submission</h1>
      <b-card
        bg-variant="primary"
        text-variant="dark"
        class="sign-in-card mb-4"
        title="Form a team"
      >
        <b-card-text
          >To form a team you must first decide on the people in the team. Once
          this is done you <strong>must</strong> select a leader.
        </b-card-text>
        <b-card-text
          >The leader will be responsible for submitting the project name and
          project writeup.</b-card-text
        >
        <b-card-text
          >Once each of the members of the team has locked in (including the
          leader). No more members may be added to the team, and nobody can
          leave the team</b-card-text
        >
        <b-card-text
          >After this the team leader may submit the write up. You may submit as
          many times as you like until the hacking period ends. Only your last
          submission will be considered.
        </b-card-text>
        <b-card-text>
          If you submit after the hacking period ends you
          <strong>may be disqualified!</strong>
        </b-card-text>
        <b-card-footer
          >If you have any questions or concerns, please contact the hackathon
          organisers at <strong>info@ichack.org</strong>.</b-card-footer
        >
      </b-card>

      <b-card
        v-if="!inTeam"
        bg-variant="dark"
        text-variant="success"
        class="sign-in-card mb-4"
        title="Create team"
      >
        <b-form-group
          id="input-group-1"
          label="Project name:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="projectName"
            required
            placeholder="Enter project name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Team leader:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            type="text"
            disabled
            v-model="userName"
            required
            placeholder="Enter team leader"
          ></b-form-input>
        </b-form-group>

        <b-button
          :disabled="busy"
          variant="success"
          class="text-dark"
          @click="createTeam"
          >Create team</b-button
        >
      </b-card>

      <b-card
        v-if="inTeam && !team?.locked"
        bg-variant="dark"
        text-variant="success"
        class="sign-in-card mb-4"
        title="My Team"
      >
        <b-card-text>
          Your team currently consists of the following members:
        </b-card-text>
        <b-card-text>
          <strong>Team leader:</strong> {{ team.leader.name }}
          <br />
          <strong>Team members:</strong>
          <ul>
            <li v-for="teamMember in team.members" :key="teamMember.uid">
              {{ teamMember.name }}
            </li>
          </ul>
        </b-card-text>
        <b-card-text>
          <b-button :disabled="busy" @click="refresh"
            >Refresh team list</b-button
          ></b-card-text
        >
        <b-card-text>
          Add team members using this join code
          <strong class="text-danger">{{ team.tid }}</strong>
        </b-card-text>
        <div class="bg-light d-flex justify-content-center p-4 rounded mb-4">
          <img
            :src="
              'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' +
              team.tid
            "
          />
        </div>
      </b-card>

      <b-card
        v-if="!inTeam"
        bg-variant="dark"
        text-variant="success"
        class="sign-in-card mb-4"
        title="Join team"
      >
        <b-card-text>
          You must enter the team code in order to join a team. Ask your team
          leader for the team code.
        </b-card-text>
        <b-button v-b-modal.showScanner>Show Scanner</b-button>
        <b-form-group id="input-group-4" label="Team code:" label-for="input-4">
          <b-form-input
            id="input-4"
            type="text"
            v-model="teamCode"
            required
            placeholder="Enter team code"
          ></b-form-input>
          <!-- https://gruhn.github.io/vue-qrcode-reader/demos/DecodeAll.html -->
          <b-modal
            id="showScanner"
            ok-only
            header-close-content=""
            ok-title="Cancel"
            @show="turnCameraOn"
            ok-variant="danger"
            ><qrcode-stream @decode="onDecode" @init="onInit"
          /></b-modal>
        </b-form-group>
        <b-button
          :disabled="busy"
          variant="success"
          class="text-dark"
          @click="joinTeam"
          >Join team</b-button
        >
      </b-card>

      <b-card
        v-if="inTeam && !team?.locked && team?.leader.uid === uid"
        bg-variant="danger"
        text-variant="dark"
        class="sign-in-card mb-4"
        title="Delete team"
      >
        <b-card-text>
          If you wish to join another team, you must first delete your current
          team.
        </b-card-text>
        <b-card-text>
          All existing team members will also be removed.
        </b-card-text>
        <b-button
          :disabled="busy"
          variant="warning"
          class="text-dark"
          @click="deleteTeam"
          >Delete team</b-button
        >
      </b-card>
      <b-card
        v-if="inTeam && !team?.locked && team?.leader.uid !== uid"
        bg-variant="danger"
        text-variant="dark"
        class="sign-in-card mb-4"
        title="Leave team"
      >
        <b-card-text>
          If you wish to join another team, you must first leave your current
          team.
        </b-card-text>

        <b-button
          :disabled="busy"
          variant="warning"
          class="text-dark"
          @click="leaveTeam"
          >Leave team</b-button
        >
      </b-card>

      <b-card
        v-if="inTeam && !team?.locked && team?.leader.uid === uid"
        bg-variant="danger"
        text-variant="dark"
        class="sign-in-card mb-4"
        title="Lock  team"
      >
        <b-card-text> Warning this operation cannot be undone</b-card-text>
        <b-card-text>
          to make sure that you are reading this message please ignore the
          button and click <span @click="lockTeam">[this text] </span>instead.
        </b-card-text>
        <b-button :disabled="busy" variant="warning" class="text-dark"
          >Lock team</b-button
        >
      </b-card>

      <b-card
        v-if="inTeam && team?.locked"
        bg-variant="primary"
        text-variant="dark"
        class="sign-in-card mb-4"
        :title="`Submit write up for ${team.name}`"
      >
        List of URLs to write ups
        <b-list-group v-if="writeup.urls.length > 0" class="card mt-4">
          <b-list-group-item
            v-for="(item, i) in writeup.urls"
            :key="i"
            class="d-flex justify-content-between align-items-center"
          >
            {{ item }}
            <b-badge class="c-pointer" @click="writeup.urls.splice(i, 1)" pill
              >Remove</b-badge
            >
          </b-list-group-item>
        </b-list-group>
        <b-input-group prepend="URL:" class="mt-3">
          <b-form-input
            id="border-input"
            type="text"
            v-model="newUrl"
            required
            placeholder="Enter URL"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="busy" @click="addItem">Add</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-card-text class="mt-4 mb-2">Write up</b-card-text>
        <div class="row">
          <div class="col-md-6">
            <b-form-textarea
              id="textarea"
              v-model="writeup.text"
              placeholder="Enter text"
              class="h-100"
            ></b-form-textarea>
          </div>
          <div class="col-md-6 text-light">
            <vue-markdown :html="false" :source="writeup.text" />
          </div>
        </div>
        <b-card-text class="mt-4 mb-2">DoCSoc Category</b-card-text>

        <b-form-select
          v-model="categories.docsoc"
          :options="docsocCategories"
        ></b-form-select>
        <b-card-text class="mt-4 mb-2">Sponsor Category</b-card-text>

        <b-form-select
          v-model="categories.sponsor"
          :options="sponsorCategories"
        ></b-form-select>

        <b-button
          variant="success"
          class="text-dark mt-4"
          @click="submitWriteup"
          :disabled="timeUp"
          >Submit Write up</b-button
        >
        <h5 class="text-dark mt-4" v-if="!timeUp">
          {{ this.secondsToString }}
        </h5>
        <h5 class="text-dark mt-4" v-if="timeUp">Time's Up!</h5>
      </b-card>
      <b-card bg-variant="dark" title="Present" v-if="inTeam && team?.locked">
        <b-card-text>
          Choose the times to present your project to the DoCSoc team and the
          sponsors. Teams will be given 5 minutes to present their project.
        </b-card-text>
        <b-card-text>
          Please note if you change your category your booking will be removed.
        </b-card-text>
        <!-- <b-card-text v-if="team?.categories">
          Your current categories are DoCSoc:
          <strong>{{ team.categories.docsoc }}</strong> and Sponsor:
          <strong>{{ team.categories.sponsor }}</strong>
        </b-card-text> -->
        <b-card-text>
          We highly recommend that you leave a gap of at least 30 minutes
          between your booking for the DoCSoc category and the sponsor category.
        </b-card-text>
        <b-table hover :items="tableData" :fields="fields" dark variant="dark">
          <template #cell(status)="data">
            <span v-if="data.item.docsoc?.tid === team.tid"
              >Your docsoc Slot
              {{ bookings[categories.docsoc].title }}
              Room: {{ bookings[categories.docsoc].room }}
            </span>
            <span v-else-if="data.item.sponsor?.tid === team.tid"
              >Your sponsor slot {{ bookings[categories.sponsor].title }} Room:
              {{ bookings[categories.sponsor].room }}</span
            >
            <span v-else>
              Sponsor slot: {{ data.item.sponsor?.status }} <br />DoCSoC slot:
              {{ data.item.docsoc?.status }}
            </span>
          </template>
          <template #cell(select)="data">
            <b-button-group>
              <b-button
                class="text-dark"
                variant="info"
                @click="selectBooking(data.item.docsoc, `docsoc`)"
                :disabled="data.item.docsoc?.status !== 'Available'"
                >Book DoCSoc</b-button
              >
              <b-button
                class="text-dark"
                variant="warning"
                :disabled="data.item.sponsor?.status !== 'Available'"
                @click="selectBooking(data.item.sponsor, `sponsor`)"
                >Book Sponsor</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import baseSubmission from "@/data/baseSubmission.json";
import prizeCategories from "@/data/prizeCategories.json";
import VueMarkdown from "vue-markdown-v2";
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapGetters, mapState } from "vuex";
import BackButton from "@/components/BackButton.vue";

export default {
  name: "SubmitPage",
  components: {
    VueMarkdown,
    QrcodeStream,
    BackButton,
  },
  mounted() {
    this.refresh();

    this.timeLeft = Math.floor((this.endTime - Date.now()) / 1000);
    console.log(this.timeLeft, this.endDate, Date.now());
    this.timerInterval = setInterval(this.decTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  data() {
    return {
      fields: [
        {
          key: "time",
          label: "Time",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "select",
          label: "Select",
        },
      ],
      busy: false,
      camera: "off",
      showScanner: false,
      projectName: "",
      teamLeader: "",
      categories: {
        sponsor: "",
        docsoc: "",
      },
      teamCode: "",
      writeup: baseSubmission,
      newUrl: "",
      prizeCategories,
      markdownItems: [
        { element: "Heading", markdown_syntax: "# H1" },
        { element: "Bold", markdown_syntax: "**bold**" },
        { element: "Italics", markdown_syntax: "*Italics*" },
        { element: "Blockquote", markdown_syntax: "> quote" },
        { element: "Ordered List", markdown_syntax: "1. First item" },
        { element: "Unordered List", markdown_syntax: "- First item" },
        { element: "Code", markdown_syntax: "`code`" },
        { element: "Horizontal line", markdown_syntax: "---" },
        { element: "Link", markdown_syntax: "[text](https://www.example.com)" },
        { element: "Image", markdown_syntax: "![alt text](image.jpg)" },
      ],
      timeLeft: 0,
      endTime: new Date("Feb 5, 2023 12:00:00").getTime(),
      timerInterval: null,
    };
  },
  watch: {
    team: {
      handler: function (val) {
        if (val) {
          this.projectName = val.projectName;
          this.categories = val.categories;
          this.writeup = val.writeup;
        }
      },
      immediate: true,

      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      isSignedIn: "isSignedIn",
      userName: "userName",
      idToken: "idToken",
      uid: "uid",
      remoteCategories: "sharedStore/categories",
      bookings: "sharedStore/bookings",
      sponsorTable: "sponsorStore/table",
      docsocTable: "docsocStore/table",
    }),
    ...mapState(["team", "inTeam"]),
    docsocCategories() {
      return this.remoteCategories
        .filter((c) => c.type === "docsoc")
        .map((c) => {
          return { text: c.title, value: c.value };
        });
    },
    sponsorCategories() {
      return this.remoteCategories
        .filter((c) => c.type === "sponsor")
        .map((c) => {
          return { text: c.title, value: c.value };
        });
    },
    tableData() {
      try {
        return Object.keys(this.sponsorTable).map((key) => {
          let docsoc = this.docsocTable[key];
          let sponsor = this.sponsorTable[key];
          return {
            time: key,
            sponsor,
            docsoc,
            _rowVariant:
              this.isOwnBooking(docsoc) || this.isOwnBooking(sponsor)
                ? "danger"
                : "",
          };
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    secondsToString() {
      var hours = Math.floor(this.timeLeft / 3600);
      var minutes = Math.floor((this.timeLeft % 3600) / 60);
      var seconds = (this.timeLeft % 3600) % 60;
      return `${hours}h:${minutes}m:${seconds}s remaining`;
    },
    timeUp() {
      return this.timeLeft <= 0;
    },
  },
  async beforeUnmount() {
    await this.$store.dispatch("sponsorStore/closeDBChannel", {
      clearModule: true,
    });
    await this.$store.dispatch("docsocStore/closeDBChannel", {
      clearModule: true,
    });
  },
  methods: {
    ...mapActions(["fetchSubmitState"]),
    isOwnBooking(booking) {
      return booking?.tid === this.team.tid;
    },
    async selectBooking(data, type) {
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "selectBooking",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              booking: data,
              type,
              category: this.categories[type],
              tid: this.team.tid,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          console.log(res);
          this.$bvToast.toast("Booking slot updated", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to update booking slot",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      }
      // console.log(booking);
    },
    isAvailable({ bookings }, type) {
      // // console.log(bookings);
      // return (
      //   bookings.filter(
      //     (b) => b.type === type && b.teamId == null && b.category
      //   ).length == 1
      // );
      return { bookings, type };
    },
    decTime() {
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1;
      }
    },
    async refresh(sameCategories) {
      this.busy = true;
      await this.fetchSubmitState().catch(() => {
        this.$bvToast.toast(" please reload the page", {
          title: "Authentication Error",
          variant: "danger",
          autoHideDelay: 5000,
        });
      });
      if (!sameCategories) {
        try {
          await this.$store.dispatch("sponsorStore/closeDBChannel", {
            clearModule: true,
          });
          await this.$store.dispatch("docsocStore/closeDBChannel", {
            clearModule: true,
          });
        } catch {
          {
            console.log("error, unable to unsubscribe");
          }
        }

        try {
          await this.$store.dispatch("sponsorStore/openDBChannel", {
            pathVariables: { categoryName: this.categories.sponsor },
          });
          await this.$store.dispatch("docsocStore/openDBChannel", {
            pathVariables: { categoryName: this.categories.docsoc },
          });
        } catch (error) {
          console.log("unalbe to subscribe");
        }
      }
      this.busy = false;
    },
    async createTeam() {
      this.busy = true;
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "createTeam",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              categories: this.categories,
              projectName: this.projectName,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          console.log(res);
          this.$bvToast.toast("you can now invite members to join", {
            title: "Team created",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to create team",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh();
      }
      console.log("Create team");
    },
    async joinTeam() {
      this.busy = true;
      try {
        let response = await fetch(process.env.VUE_APP_API_HOST + "joinTeam", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: this.idToken,
            team: this.teamCode,
          }),
        });
        if (response.ok) {
          let res = await response.json();
          console.log(res);
          this.$bvToast.toast("you can now invite members to join", {
            title: "Team joined",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to join team",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh();
      }
      console.log("Create team");
    },
    async lockTeam() {
      this.busy = true;
      try {
        let response = await fetch(process.env.VUE_APP_API_HOST + "lockTeam", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: this.idToken,
            team: this.team.tid,
          }),
        });
        if (response.ok) {
          let res = await response.text();
          this.$bvToast.toast("you can now submit your writeup", {
            title: "Team Locked",
            variant: "success",
            autoHideDelay: 5000,
          });
          console.log(res);
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to Delete team",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh();
      }
      console.log("Delete team");
    },
    async deleteTeam() {
      this.busy = true;
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "deleteTeam",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              team: this.team.tid,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          this.$bvToast.toast("you can now create or join another team", {
            title: "Team deleted",
            variant: "success",
            autoHideDelay: 5000,
          });
          console.log(res);
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to Delete team",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh();
      }
      console.log("Delete team");
    },
    async leaveTeam() {
      this.busy = true;
      try {
        let response = await fetch(process.env.VUE_APP_API_HOST + "leaveTeam", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: this.idToken,
            team: this.team.tid,
          }),
        });
        if (response.ok) {
          let res = await response.json();
          console.log(res);
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to Leave team",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh();
      }
      console.log("Leave team");
    },
    async submitWriteup() {
      this.busy = true;
      let changes = false;
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "submitWriteup",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              team: this.team.tid,
              writeup: this.writeup,
              categories: this.categories,
            }),
          }
        );

        if (response.ok) {
          let res = await response.json();
          changes = res.docsoc || res.sponsor;
          console.log(res);
          this.$bvToast.toast("All changes saved", {
            title: "Update successful",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to submit writeup",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.refresh(changes);
      }
      this.categories.sponsor = null;
      this.categories.docsoc = null;
      console.log("Delete team");
    },
    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    addItem() {
      if (this.newUrl === "") return;
      this.writeup.urls.push(this.newUrl);
      this.newUrl = "";
    },
    onDecode(result) {
      this.teamCode = result;
      this.$bvModal.hide("showScanner");
      this.turnCameraOff;
      console.log("Decoded from QR Code:", result);
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>

<style>
#border-input {
  border: 13px solid #fff;
}
.c-pointer {
  cursor: pointer;
}
</style>
e
