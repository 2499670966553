<template>
  <b-container class="pt-4">
    <b-card-text> Category</b-card-text>
    <b-modal
      @ok="submitJudgement(currentTeam, value)"
      id="judge-modal"
      size="xl"
      title="Judge Team"
      hide-header-close
      ok-only
      ok-title="Done"
      ok-variant="danger"
    >
      <b-card-text>Team Name: {{ currentTeam?.team }}</b-card-text>
      <b-card-text>
        Memebers:
        <b-badge
          variant="info"
          class="text-dark mb-4"
          v-for="(member, i) in currentTeam?.members"
          :key="i"
        >
          {{ member.name }}
        </b-badge>
      </b-card-text>
      <b-card-text class="mb-4">
        Links:
        <b-badge
          variant="info"
          class="text-dark ml-2"
          v-for="(url, i) in teamRecord?.writeup.urls"
          :key="i"
        >
          <a @click="copyURL(url)" class="text-dark">{{ url }}</a>
        </b-badge>
      </b-card-text>
      <b-card bg-variant="info" title="writeup" class="mb-4 text-dark">
        <vue-markdown :html="false" :source="teamRecord?.writeup.text" />
      </b-card>

      <b-card
        bg-variant="danger"
        class="text-dark mb-4"
        v-for="(metric, i) in rubric.metrics"
        :key="metric.name"
        :title="metric.name"
      >
        <b-card-text class="text-dark">{{ metric.description }}</b-card-text>
        <b-button
          :variant="showExamples ? 'info' : 'success'"
          class="mb-4 text-dark"
          @click="showExamples = !showExamples"
        >
          {{ showExamples ? "Hide Examples" : "show Examples" }}</b-button
        >
        <b-form-input
          id="range-2"
          v-model="value[i]"
          type="range"
          min="0"
          max="5"
          step="0.01"
        ></b-form-input>
        <b-badge variant="success" class="text-dark mt-2">
          <div class="h4 mb-1 mx-2">Value: {{ value[i] }}</div>
        </b-badge>
        <div v-if="showExamples">
          <boundDescriptor
            class="mb-4 mt-4"
            name="High bound"
            :description="metric.high_bound.description"
            :examples="metric.high_bound.examples"
          />
          <boundDescriptor
            class="mb-4"
            name="Medium bound"
            :description="metric.medium_bound.description"
            :examples="metric.medium_bound.examples"
          />
          <boundDescriptor
            class="mb-4"
            name="Low bound"
            :description="metric.low_bound.description"
            :examples="metric.low_bound.examples"
          />
        </div>
      </b-card>
    </b-modal>

    <b-form-select
      v-model="category"
      :options="selectCategories"
    ></b-form-select>
    <b-table hover :items="tableData" :fields="fields" dark variant="dark">
      <template #cell(status)="data">
        <div v-if="data.item.status !== 'Available'">
          Project:{{ data.item.team }} Members:
          {{ data.item.members.map((x) => x.name).join(", ") }}
        </div>
        <div v-else>
          <b-badge variant="info" class="text-dark">~Slot not used~</b-badge>
        </div>
      </template>
      <template #cell(select)="data">
        <b-button-group>
          <b-button
            class="text-dark"
            variant="info"
            @click="judgeTeam(data.item)"
            :disabled="data.item.status == 'Available'"
            >Judge Team</b-button
          >
          <b-button
            class="text-light"
            variant="dark"
            :disabled="data.item.status == 'Available'"
            @click="setStatus(data.item.team.tid, 'No Show', data.item.time)"
            >Mark No Show</b-button
          >
          <b-button
            class="text-dark"
            variant="info"
            v-if="data.item.status != 'Complete'"
            :disabled="data.item.status == 'Available'"
            @click="setStatus(data.item.team.tid, 'Complete', data.item.time)"
            >Mark Complete</b-button
          >
          <b-button
            v-else
            class="text-dark"
            variant="info"
            :disabled="data.item.status == 'Available'"
            @click="setStatus(data.item.team.tid, 'Booked', data.item.time)"
            >Mark TODO</b-button
          >
        </b-button-group>
      </template>
    </b-table>
    <!-- <h3 class="text-primary mb-4">Judging</h3>
    <div class="col-12" v-for="rubric in rubrics" :key="rubric.name">
      <b-card class="bg-secondary mb-4">
        <h3 class="text-primary">{{ rubric.name }}</h3>
        <p class="text-dark">0 = {{ rubric.zero }}</p>
        <p class="text-dark">1 = {{ rubric.one }}</p>
        <p class="text-dark">2 = {{ rubric.two }}</p>
        <p class="text-dark">3 = {{ rubric.three }}</p>
        <p class="text-dark">4 = {{ rubric.four }}</p>
        <p class="text-dark">5 = {{ rubric.five }}</p>
        <b-row class="my-1">
          <b-col sm="3">
            <label for="range"
              >{{ rubric.name }}<code>{{ range }}</code
              >:</label
            >
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="range"
              type="range"
              :min="1"
              :max="5"
              v-model="awesomeness"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-card>
    </div> -->
  </b-container>
</template>

<script>
import boundDescriptor from "@/components/boundDescriptor.vue";
import { mapGetters, mapState } from "vuex";

import VueMarkdown from "vue-markdown-v2";
import JudgeRubrics from "../data/rubrics.json";

export default {
  data() {
    return {
      value: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      showExamples: false,
      category: "docsoc1",
      busy: false,
      currentTeam: null,
      rubrics: JudgeRubrics,
      fields: [
        {
          key: "time",
          label: "Time",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "select",
          label: "Select",
        },
      ],
    };
  },
  components: {
    boundDescriptor,
    VueMarkdown,
  },
  computed: {
    ...mapState("judgeStore", {
      teams: (state) => state.teams,
    }),
    teamRecord() {
      if (this.currentTeam) {
        return this.teams[this.currentTeam.tid];
      }
      return null;
    },
    ...mapGetters({
      isSignedIn: "isSignedIn",
      userName: "userName",
      idToken: "idToken",
      uid: "uid",
      remoteCategories: "sharedStore/categories",
      rubric: "sharedStore/rubric",

      bookings: "sharedStore/bookings",
      docsocTable: "docsocStore/table",
    }),

    selectCategories() {
      return this.remoteCategories.map((c) => {
        return { text: c.title, value: c.value };
      });
    },
    tableData() {
      try {
        return Object.keys(this.docsocTable).map((key) => {
          let docsoc = this.docsocTable[key];
          let _rowVariant = "";
          switch (docsoc.status) {
            case "Complete":
              _rowVariant = "primary";
              break;
            case "Available":
              _rowVariant = "";
              break;
            case "Booked":
              _rowVariant = "success";
              break;
            case "No Show":
              _rowVariant = "danger";
              break;
            default:
              _rowVariant = "secondary";
              break;
          }
          return {
            time: key,
            ...docsoc,
            _rowVariant,
          };
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    },
  },
  async beforeUnmount() {
    await this.$store.dispatch("docsocStore/closeDBChannel", {
      clearModule: true,
    });
  },
  methods: {
    async refresh() {
      this.busy = true;
      try {
        await this.$store.dispatch("docsocStore/closeDBChannel", {
          clearModule: true,
        });
      } catch {
        {
          console.log("error, unable to unsubscribe");
        }
      }

      try {
        await this.$store.dispatch("docsocStore/openDBChannel", {
          pathVariables: { categoryName: this.category },
        });
      } catch (error) {
        console.log("unalbe to subscribe");
      }
      this.busy = false;
    },
    async judgeTeam(team) {
      console.log(team);
      this.currentTeam = team;
      if (this.teamRecord?.grades?.[this.uid]) {
        console.log(
          this.teamRecord?.grades?.[this.uid].grades.map((g) => `${g}`)
        );
        this.value = this.teamRecord?.grades?.[this.uid].grades.map(
          (g) => `${g}`
        );
      } else {
        this.value = [
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
        ];
      }
      this.$bvModal.show("judge-modal");
    },
    async copyURL(url) {
      this.copyTextToClipboard(url);
      this.$bvToast.toast(`Copied to ${url} clipboard  `, {
        title: "Copied to clipboard",
        variant: "success",
        autoHideDelay: 5000,
      });
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    async setStatus(team, status, time) {
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "setTeamStatus",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              team,
              time,
              status,
              category: this.category,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          console.log(res);
          this.$bvToast.toast("Booking slot updated", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to update booking slot",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitJudgement(team, values) {
      console.log(this.teams[team.tid]);
      let grades = values.map((x) => parseFloat(x));
      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "setTeamJudgement",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: this.idToken,
              grades,
              team: team.tid,
              category: this.category,
            }),
          }
        );
        if (response.ok) {
          let res = await response.text();
          console.log(res);
          this.$bvToast.toast("Grade recorded", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
        } else {
          const text = await response.text();
          this.$bvToast.toast(text, {
            title: "Unable to update grade",
            variant: "danger",
            autoHideDelay: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    category: {
      handler: function () {
        this.refresh();
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
