import "@babel/polyfill";
import "mutationobserver-shim";
import SimpleAnalytics from "simple-analytics-vue";

import CursorFx from "@luxdamore/vue-cursor-fx";
import "@luxdamore/vue-cursor-fx/dist/CursorFx.css";
import router from "./routes";
import store from "./store";
// Install
import { sync } from "vuex-router-sync";
sync(store, router); // done. Returns an unsync callback fn

import Vue from "vue";
import InstantSearch from "vue-instantsearch";
import App from "./App.vue";
import fb from "./firebase";
import "./plugins/bootstrap-vue";
Vue.use(CursorFx);
Vue.use(SimpleAnalytics, { domain: "kia.ichack.org" });

Vue.config.productionTip = false;

Vue.use(InstantSearch);
// PROD

Vue.prototype.$fb = fb;
store.$fb = fb;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
