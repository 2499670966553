<template>
  <b-button
    :variant="variantName"
    :class="`btn ${extraStyle}`"
    @click="() => $router.go(-1)"
  >
    &lt;- Back
  </b-button>
</template>

<script>
export default {
  props: {
    "variant-name": String,
    "extra-style": String,
  },
};
</script>
