const firestoreModule = {
  firestorePath: "shared/",
  // The path to a "collection" or single "document" in firestore.
  // You can use `{userId}` which will be replaced with the user Id.
  firestoreRefType: "collection",
  // `'collection'` or `'doc'`. Depending on your `firestorePath`.
  moduleName: "sharedStore",
  // The module name. eg. `'userItems'`
  // Can also be a nested module, eg. `'user/items'`
  statePropName: "data",
  // The name of the property where the docs or doc will be synced to.
  // always best to set to `'data'` imo!
  // If left blank it will be synced on the state of the module.

  namespaced: true,
  // this is automatically added! See more info at: https://vuex.vuejs.org/guide/modules.html#namespacing

  // EVERYTHING BELOW IS OPTIONAL (only include what you use)
  // Related to the 2-way sync:
  sync: {
    where: [],
    orderBy: [],
    fillables: [],
    guard: [],
    defaultValues: {},
    debounceTimerMs: 1000,
  },

  // When docs on the server side are changed:
  serverChange: {
    convertTimestamps: {},
  },

  // When docs are fetched through `dispatch('module/fetch', {clauses})`.
  fetch: {
    // The max amount of documents to be fetched. Defaults to 50.
    docLimit: 1000,
  },

  // You can also add custom state/getters/mutations/actions. These will be added to your module.
  state: {},
  getters: {
    categories(state) {
      return state.data.categories.values;
    },
    bookings(state) {
      return state.data.bookings.categories;
    },
    broadcasts(state) {
      return state.data.broadcasts;
    },
    rubric(state) {
      return state.data.rubric;
    },
  },
  mutations: {},
  actions: {},
};
export default firestoreModule;
