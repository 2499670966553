<template>
  <a :href="url" target="_blank" rel="noopener" data-cursor-hover>
    <div class="sponsor-card m-4 pt-4">
      <div class="sponsor-card__logo">
        <img :src="logo" alt="Sponsor Logo" />
      </div>
      <div class="sponsor-card__content">
        <h4 class="sponsor-card__name text-dark">{{ name }}</h4>
        <!-- <p class="sponsor-card__description">{{ description }}</p> -->
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "SponsorCard",
  props: {
    name: {
      type: String,
      default: "Name",
    },
    description: {
      type: String,
      default: "Description",
    },
    url: {
      type: String,
      default: "https://google.com",
    },
    logo: {
      type: String,
      default: "https://picsum.photos/300/300",
    },
  },
};
</script>

<style lang="scss">
.sponsor-card {
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  justify-content: space-between;
  overflow: hidden;
  height: 300px;
  width: 300px;
}

.sponsor-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.sponsor-card__logo {
  width: 100%;
  max-width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sponsor-card__logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.sponsor-card__content {
  text-align: center;
  padding: 20px;
}

.sponsor-card__name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sponsor-card__description {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
// .sponsor-card {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   margin: 1rem;
//   padding: 1rem;
//   border-radius: 5px;
//   transition: all 0.3s ease-in-out;
//   &:hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
// }
// .sponsor-card__logo {
//   width: 200px;
//   height: 200px;
//   margin-right: 1rem;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// }
// .sponsor-card__content {
//   text-align: left;
//   margin-top: 1rem;
// }
// .sponsor-card__name {
//   color: $success;
// }
</style>
