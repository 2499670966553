<template>
  <div class="bg-primary pt-4 pb-4">
    <div class="container">
      <BackButton variant-name="dark" extra-style="text-primary" />
      <h1 class="text-secondary mb-4 mt-4">Get Started</h1>

      <div
        class="row align-middle mb-4 justify-center"
        v-for="item in quickstart"
        :key="item.id"
      >
        <div class="col-md-2 d-flex centerize">
          <b-card
            bg-variant="dark"
            text-variant="success"
            class="number-card mb-4"
            :title="item.id"
          ></b-card>
        </div>
        <div class="col-md-10">
          <b-card
            :title="item.title"
            text-variant="success"
            bg-variant="dark"
            class="content-card h-100"
          >
            <b-card-text> <span v-html="item.description"></span></b-card-text>
            <maybe-link-vue
              v-if="item.link"
              :to="item.link"
              :internal="item.internal"
            >
              <b-button variant="outline-primary">{{
                item.linkText
              }}</b-button></maybe-link-vue
            >
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaybeLinkVue from "@/components/MaybeLink.vue";
import Quickstart from "@/data/get-started.json";
import BackButton from "@/components/BackButton.vue";
export default {
  name: "GetStartedPage",
  components: {
    MaybeLinkVue,
    BackButton,
  },
  data: () => {
    return {
      quickstart: Quickstart,
    };
  },
};
</script>

<style lang="scss" scoped>
.number-card {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    margin: 0;
    font-size: 1.9rem;
  }

  .justify-center {
    justify-content: center;
  }

  .content-card {
    min-height: 300px;
  }
}

.centerize {
  justify-content: center;
}
</style>
