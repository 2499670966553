<template>
  <div class="container pt-4">
    <BackButton />
    <h1 class="text-secondary mb-4 mt-4">Code of conduct</h1>
    <b-card
      bg-variant="primary"
      text-variant="dark"
      class="text-center sign-in-card mb-4"
    >
      <b-card-text
        >The purpose of this code of conduct is to ensure that all participants
        in IC Hack have a safe and inclusive experience. This includes
        attendees, organisers, sponsors, volunteers, and judges.</b-card-text
      >
      <b-card-text
        >If you have any questions or concerns, please contact the hackathon
        organisers at <strong>info@ichack.org</strong>.</b-card-text
      >
      <b-card-footer
        >This code of conduct is based on the
        <a class="text-dark" href="https://hackcodeofconduct.org/"
          >Hack Code of Conduct</a
        >.</b-card-footer
      >
    </b-card>
    <h2>In brief</h2>

    IC Hack is dedicated to providing a safe and comfortable environment and
    harassment-free experience for everyone, regardless of the following:
    <ul>
      <li>gender</li>
      <li>gender identity and expression</li>
      <li>age</li>
      <li>sexual orientation</li>
      <li>disability</li>
      <li>physical appearance</li>
      <li>body size</li>
      <li>race</li>
      <li>ethnicity</li>
      <li>nationality</li>
      <li>religion</li>
      <li>political views</li>
      <li>previous hackathon attendance or lack thereof</li>
      <li>computing experience or lack of</li>
      <li>chosen programming language or tech stack</li>
    </ul>
    <p>
      We do not tolerate harassment of IC Hack participants in any form. Sexual
      language and imagery is not appropriate for any part of IC Hack,
      including:
    </p>

    <ul>
      <li>hacks</li>
      <li>talks, presentations, or demos</li>
      <li>workshops</li>
      <li>social media posts</li>
      <li>any other online media</li>
    </ul>

    <p>
      IC Hack participants violating these rules
      <strong>may be sanctioned or expelled</strong> from IC Hack at the
      discretion of the organisers.
    </p>

    <h2 class="ui dividing header">The Less Quick Version</h2>

    <p>
      Harassment includes offensive verbal comments related to gender, gender
      identity and expression, age, sexual orientation, disability, physical
      appearance, body size, race, ethnicity, nationality, religion or political
      views, sexual images in public spaces, deliberate intimidation, stalking,
      following, photography or audio/video recording against reasonable
      consent, sustained disruption of talks or other events, inappropriate
      physical contact, and unwelcome sexual attention.
    </p>

    <p>
      Photography is encouraged, but other participants must be given a
      reasonable chance to opt out from being photographed. If they object to
      the taking of their photograph, comply with their request. It is
      inappropriate to take photographs in contexts where people have a
      reasonable expectation of privacy (in bathrooms or where participants are
      sleeping).
    </p>

    <p>
      Participants asked to stop any harassing behavior are expected to comply
      immediately.
    </p>

    <p>
      As this is a hackathon, we like to explicitly note that the hacks created
      at IC Hack are equally subject to the anti-harassment policy.
    </p>

    <p>
      Sponsors and partners are also subject to the anti-harassment policy. In
      particular, sponsors should not use sexualised images, activities, or
      other material. Sponsor representatives (including volunteers) should not
      use sexualised clothing/uniforms/costumes, or otherwise create a
      sexualised environment.
    </p>

    <p>
      If you are being harassed, notice that someone else is being harassed, or
      have any other concerns, please contact a member of IC Hack staff
      immediately.
    </p>

    <p>
      IC Hack staff will be happy to help participants contact any local
      security or local law enforcement, provide escorts, or otherwise assist
      those experiencing harassment to feel safe for the duration of the
      hackathon. We value your attendance.
    </p>

    <p>
      If a participant engages in harassing behavior, the organisers may take
      any action they deem appropriate. This includes warning the offender,
      expulsion from the hackathon, or reporting their behaviour to local law
      enforcement.
    </p>

    <p>
      We expect participants to follow these rules at IC Hack and any affiliated
      events, and in related social media posts.
    </p>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
export default {
  components: {
    BackButton,
  },
};
</script>

<style></style>
