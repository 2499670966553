<template>
  <div class="d-flex justify-content-center align-items-center outer">
    <b-card
      bg-variant="secondary"
      text-variant="white"
      title="Sign In"
      class="text-center sign-in-card"
    >
      <b-card-text>Enter your email</b-card-text>
      <b-form-input
        v-model="email"
        type="text"
        placeholder="Email"
      ></b-form-input>
      <b-button
        class="btn btn-dark mt-4 btn-block"
        @click="signIn"
        :disabled="busy"
      >
        {{ buttonText }}
      </b-button>
      <hr />
      <a href="https://ichack.org">Return to main site</a>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "TicketPage",
  mounted() {
    if (this.$store.getters.isSignedIn) this.$router.push("/ticket");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailCode = urlParams.has("token");
    if (emailCode) {
      const token = urlParams.get("token");
      console.log(token, "token");
      this.$store
        .dispatch("signInUser", token)
        .then(() => {
          console.log("signed in");
          this.$router.push("/main");
          window.history.replaceState({}, document.title, "/#/" + "sign-in");
          this.saEvent(`SIGN_IN_GOOD`);
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(
            "The token is invalid enter your email and try again",
            {
              title: "Unable to sign in",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
          this.saEvent(`SIGN_IN_BAD_TOKEN`);
        });
    } else {
      console.log("urlParams has no token");
      this.saEvent(`SIGN_IN_NO_TOKEN`);
    }
  },
  methods: {
    signIn() {
      this.busy = true;
      this.$store
        .dispatch("sendEmailCode", this.email)
        .then(({ message, meta }) => {
          this.$bvToast.toast(message, meta);
          this.saEvent(`SIGN_IN_EMAIL_SENT`, { email: this.email, message });
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
  computed: {
    emailCode() {
      return this.$route.query.token ? true : false;
      // const auth = getAuth();

      // return isSignInWithEmailLink(auth, window.location.href);
    },
    buttonText() {
      return this.emailCode ? "Sign in" : "Send me a link";
    },
  },
  components: {},
  data: () => {
    return {
      busy: false,
      email: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.outer {
  background: $primary;
  width: 100vw;
  height: 100vh;
}
.sign-in-card {
  max-width: 30rem;
}
</style>
