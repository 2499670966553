<template>
  <section-timeline-vue class="mt-4" />
</template>

<script>
import SectionTimelineVue from "@/components/SectionTimeline.vue";
export default {
  name: "SponsorPage",
  components: {
    SectionTimelineVue,
  },
};
</script>

<style></style>
