<template>
  <div>
    <div class="container">
      <BackButton variant-name="success" extra-style="text-dark" />
      <b-card id="sponsor-info-cell" class="bg-primary mt-4">
        <h3 class="text-dark">Sponsors</h3>
        <h6 class="text-dark">
          IC Hack is only made possible thanks to the generous support of our
          sponsors.
        </h6>
        <p class="text-dark"></p>
      </b-card>

      <h3>Title Sponsor</h3>

      <div
        class="row justify-content-center mt-4 mb-4"
        style="background: #95e2d6; border-radius: 15px"
      >
        <div class="col-12 d-flex justify-content-center">
          <sponsor-card
            :name="title.name"
            :logo="title.logo"
            :url="title.url"
            :socials="title.socials"
          />
        </div>
      </div>
      <h3>Gold Sponsors</h3>

      <div
        class="row justify-content-center"
        style="background: #ffffff2b; border-radius: 1000px"
      >
        <div
          v-for="sponsor in gold"
          :key="sponsor.name"
          class="col-md-6 justify-content-center d-flex"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3>Silver Sponsors</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in silver"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3>Bronze Sponsors</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in bronze"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3>Partners</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in partner"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sponsorData from "../data/sponsors.json";
import SponsorCard from "./SponsorCard.vue";
import BackButton from "./BackButton.vue";

export default {
  name: "SectionSponsors",
  components: {
    SponsorCard,
    BackButton,
  },
  data: () => {
    return {
      sponsors: sponsorData,
    };
  },
  computed: {
    title() {
      return this.sponsors.title;
    },
    gold() {
      return this.sponsors.gold;
    },
    silver() {
      return this.sponsors.silver;
    },
    bronze() {
      return this.sponsors.bronze;
    },
    partner() {
      return this.sponsors.partner;
    },
  },
};
</script>

<style>
#sponsor-info-cell {
  border-radius: 15px;
  margin-bottom: 4rem;
}
</style>
