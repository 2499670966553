import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseConfig from "./firebaseConfig.json";
import router from "./routes";
import store from "./store";

import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
const fb = initializeApp(firebaseConfig);
console.log(process.env.VUE_APP_API_HOST);
const auth = getAuth();
const db = getFirestore(fb);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code == "failed-precondition") {
    console.log("Too many tabs open");
  } else if (err.code == "unimplemented") {
    console.log(err);
  }
});

onAuthStateChanged(auth, async (user) => {
  if (user) {
    store.state.user = user;
    await store.dispatch("setToken", user);
    await store.dispatch("userStore/openDBChannel");
    await store.dispatch("sharedStore/openDBChannel");
    try {
      await store.dispatch("judgeStore/openDBChannel");
    } catch {
      console.log("Im not  a judge");
    }

    await store.dispatch("loadUserPage");
    store.state.ready = true;
  } else if (router.currentRoute.path !== "/sign-in") {
    router.replace("/sign-in");
    console.log(router);
  }
  store.state.ready = true;
});
export default { fb, db, auth };
