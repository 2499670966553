import docsocStore from "@/docsocStore";
import judgeDataModule from "@/judgeStore";
import sharedDateModule from "@/sharedStore";
import sponsorStore from "@/sponsorStore";
import userDataModule from "@/userStore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
// import firebase from "firebase/compat/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import Vue from "vue";
import Vuex from "vuex";
import createEasyFirestore from "vuex-easy-firestore";
import VuexPersistence from "vuex-persist";
import firebase from "./vuexfire";
const easyFirestore = createEasyFirestore(
  [
    userDataModule,
    judgeDataModule,
    sharedDateModule,
    docsocStore,
    sponsorStore,
  ],
  { logging: true, FirebaseDependency: firebase } // pass Firebase like this. Mind the Capital F!
);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ claims: state.claims, userPage: state.userPage }),
});
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    claims: null,
    ready: false,
    email: "",
    team: null,
    inTeam: false,
    user: null,
    userPage: null,
    location: window.location.href,
    token: null,
    candidateData: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setCandidateData(state, candidateData) {
      state.candidateData = candidateData;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setPage(state, page) {
      state.userPage = page;
    },
    setTeam(state, team) {
      state.inTeam = team !== null && team !== undefined;
      state.team = team;
    },
    setToken(state, token) {
      state.token = token;
    },
    setTokenResult(state, { claims }) {
      state.claims = claims;
    },
  },
  getters: {
    getCandidateData(state) {
      return state.candidateData;
    },
    uid(state) {
      return state.user?.uid;
    },
    user(state) {
      state.user;
    },
    isSignedIn(state) {
      return state.user != null;
    },
    getEmail(state) {
      return state.email;
    },
    getName(state) {
      return state.userPage?.name;
    },
    userPage(state) {
      return state.userPage;
    },
    userName(state) {
      return state?.userPage.name;
    },
    idToken(state) {
      return state.token;
    },
  },
  actions: {
    async setToken({ commit }, user) {
      commit("setToken", await user.getIdToken(true));
      commit("setTokenResult", await user.getIdTokenResult(true));
    },
    async fetchSubmitState({ getters, commit }) {
      // make request using auth token

      try {
        let response = await fetch(process.env.VUE_APP_API_HOST + "myTeam", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: getters.idToken }),
        });
        if (response.ok) {
          let res = await response.json();
          console.log(res);
          commit("setTeam", res.team);
        } else {
          alert(await response.text());
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCandidateData({ getters, commit }, hid) {
      // make request using auth token

      try {
        let response = await fetch(
          process.env.VUE_APP_API_HOST + "getSingleUsersEmploymentStats",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: getters.idToken, hid }),
          }
        );
        if (response.ok) {
          let res = await response.json();
          console.log(res);
          commit("setCandidateData", res);
        } else {
          alert(await response.text());
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadUserPage({ getters, commit, $fb }) {
      if (!getters.uid) return;
      console.log("loadingp page", getters.uid);
      const db = getFirestore($fb);
      const docRef = doc(db, "users", getters.uid);
      const docSnap = await getDoc(docRef);
      console.log(docSnap);
      if (docSnap.exists()) {
        commit("setPage", docSnap.data());
      } else {
        console.log("No such document!");
      }
    },
    signInUserOnNewDevice({ dispatch, commit }, email) {
      commit("setEmail", email);
      dispatch("signInUser");
    },
    async signInUser({ getters, commit, $fb, dispatch }, token) {
      const auth = getAuth($fb);
      console.log(auth, $fb);
      if (getters.isSignedIn) {
        console.log("already signed in");
        return;
      }
      console.log("Sigining in with token", token);
      // try {
      const result = await signInWithCustomToken(auth, token);
      commit("setUser", result.user);
      dispatch("loadUserPage");
      // } catch (error) {
      //   console.log(error);
      //   throw "error signing in user";
      // }
    },
    async sendEmailCode({ commit, getters }, email) {
      if (getters.isSignedIn) {
        return;
      }
      commit("setEmail", email);
      try {
        // const auth = getAuth();

        // await sendSignInLinkToEmail(auth, email, {
        //   url: window.location.origin + "/#/sign-in",
        //   handleCodeInApp: true,
        // });

        let response = await fetch(
          process.env.VUE_APP_API_HOST + "sendMagicCode",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email }),
          }
        );

        if (response.ok) {
          return {
            message: `A login link was sent to  ${email}. Click the link to sign in`,
            meta: {
              title: "Email sent!",
              toaster: "b-toaster-top-left",
              variant: "success",
            },
          };
        } else {
          console.log(response);
          return {
            message: await response.text(),
            meta: {
              title: "Something went wrong",
              toaster: "b-toaster-top-left",
              variant: "danger",
            },
          };
        }
      } catch (error) {
        // console.log(error, email, {
        //   url: state.location,
        //   handleCodeInApp: true,
        // });
      }
      // firebase send email to user
    },
  },
  plugins: [vuexLocal.plugin, easyFirestore],
});
export default store;
