import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import conductPage from "./pages/conduct-page";
import cvPage from "./pages/cv-page";
import getStartedPage from "./pages/get-started-page";
import judgePage from "./pages/judge-page";
import landingPage from "./pages/landing-page";
import mainPage from "./pages/main-page";
import mapPage from "./pages/map-page";
import myInfoPage from "./pages/my-info-page";
import submissionPage from "./pages/page-submit.vue";
import schedulePage from "./pages/schedule-page";
import signInPage from "./pages/sign-in.vue";
import sponsorPage from "./pages/sponsor-page.vue";
import teamPage from "./pages/team-page";
import ticketPage from "./pages/ticket-page";
// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: "/land", component: landingPage },
  { path: "/ticket", component: ticketPage },
  { path: "/", component: signInPage },
  { path: "/conduct", component: conductPage },
  { path: "/main", component: mainPage },
  { path: "/start", component: getStartedPage },
  { path: "/map", component: mapPage },
  { path: "/sponsors", component: sponsorPage },
  { path: "/schedule", component: schedulePage },
  { path: "/sign-in", component: signInPage },
  { path: "/submit", component: submissionPage },
  { path: "/team", component: teamPage },
  { path: "/judge-input", component: judgePage },
  { path: "/profile", component: myInfoPage },
  { path: "/cvs", component: cvPage },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes, // short for `routes: routes`
});

// 4. Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
export default router;
