<template>
  <div>
    <NavbarFixed />
    <SectionHero />
    <SectionTimeline />
    <SectionFAQ />
    <SectionSponsors />
    <SectionTeam />
    <media-gallery />
    <SectionFooter />
  </div>
</template>

<script>
import MediaGallery from "@/components/MediaGallery.vue";
import NavbarFixed from "@/components/NavbarFixed.vue";
import SectionFAQ from "@/components/SectionFAQ.vue";
import SectionFooter from "@/components/SectionFooter.vue";
import SectionHero from "@/components/SectionHero.vue";
import SectionSponsors from "@/components/SectionSponsors.vue";
import SectionTeam from "@/components/SectionTeam.vue";
import SectionTimeline from "@/components/SectionTimeline.vue";

export default {
  name: "App",
  components: {
    NavbarFixed,
    SectionHero,
    SectionFAQ,
    SectionTeam,
    SectionFooter,
    SectionTimeline,
    SectionSponsors,
    MediaGallery,
  },
};
</script>

<style></style>
