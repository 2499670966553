<template>
  <div class="bg-primary">
    <div class="container">
      <div class="row mx-2">
        <b-button
          class="btn btn-dark mt-4 btn-block"
          @click="() => $router.push('/ticket')"
        >
          Scan your ticket
        </b-button>
      </div>
      <div class="row">
        <b-card
          v-if="org"
          bg-variant="secondary"
          text-variant="dark"
          title="Message from the  Organisers"
          class="mt-4 w-100 mx-3"
        >
          <b-card-text v-html="org.message"></b-card-text>

          <b-card-footer>Last updated: {{ org.time }}</b-card-footer>
        </b-card>
      </div>
      <div class="row mt-4">
        <div class="col-md-4 mb-4" v-for="card in cards" :key="card.id">
          <MaybeLink :to="card.link" :internal="card.internal">
            <b-card
              :bg-variant="card.link == '#' ? 'danger' : 'dark'"
              text-variant="success"
              :title="card.title"
              class="h-100"
            >
              <b-card-text class="text-white" text-variant="white"
                ><span v-html="card.description"></span
              ></b-card-text>

              <!-- <hr />
            <router-link to="/foo">Go to Foo</router-link>
            <router-link to="/bar">Go to Bar</router-link> -->
            </b-card>
          </MaybeLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MaybeLink from "../components/MaybeLink.vue";
import Cards from "../data/main-cards.json";
export default {
  name: "MainPage",
  components: {
    MaybeLink,
  },
  computed: {
    ...mapGetters({
      isSignedIn: "isSignedIn",
      userName: "userName",
      idToken: "idToken",
      uid: "uid",
      org: "sharedStore/broadcasts",
    }),
    cards() {
      return Cards.filter((card) =>
        card.show.includes(this.$store.state?.claims?.role)
      );
    },
  },
  data() {
    return {
      email: "",
      message: "Welcome to IC Hack23 powered by Cisco",
      updated: new Date().toLocaleDateString(),
    };
  },
};
</script>

<style></style>
