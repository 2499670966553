<template>
  <component :is="tag" :to="to" :href="to" @click="notif">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: null,
    },
    internal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      if (!this.to || this.to.startsWith("#")) {
        return "span";
      }
      return this.internal ? "router-link" : "a";
    },
  },
  methods: {
    notif() {
      this.saEvent(`NAVIGATE`, { to: this.to });
    },
  },
};
</script>

<style></style>
