<template>
  <div class="container pt-4">
    <BackButton />
    <h1 class="mt-4">Map</h1>
    <div class="bg-primary card p-4 mt-4">
      <img class="w-100" src="/map23.jpeg" alt="" />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
export default {
  components: {
    BackButton,
  },
};
</script>

<style></style>
