<template>
  <section-sponsors-vue class="mt-4" />
</template>

<script>
import SectionSponsorsVue from "@/components/SectionSponsors.vue";
export default {
  name: "SponsorPage",
  components: {
    SectionSponsorsVue,
  },
};
</script>

<style></style>
